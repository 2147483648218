
.profile-parent{
    display: flex ;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
}
.highlighted-text{
    color: var(--dark-orange);
}
 .highlighted-btn{
    margin: 0 0 0 28px;
    font-size: 20px;
}
 .highlighted-btn:hover{
    margin: 0 0 0 28px;
    background-color: cornsilk;
    color: black;
    font-size: 20px;
}
.profile-picture{
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow:0 1px 0px 0px var(--white) ;
    border-radius: 50%;
    height: 300px;
    width: 300px;
    margin: 0;
}
.profile-picture-background{
    height: 92%;
    width: 92%;
    background-image: url(../../assets/Home/hero.jpg);
    background-size: cover;
    border-radius: 50%;
    background-repeat: no-repeat;
}
.profile-picture-background:hover{
    transform: scale(1.07);
    transition: 1s ease-out;
}
.onHover:hover{
    transform: scale(1.5);
    color: #fafeff;
}
.card-container {
      width: 85%;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
}
    .card-container:hover{
        box-shadow: 5px 5px #888888;
        background-color: #fafeff;
    }
     /* from here starts Mydetails.css */
     .leftContainer{
        text-align: center;
        background-color: #fff;
        padding: 20px;

    }
    .rightContainer{
        color: #fff;
        background-color: #12182e;
        padding: 20px;
        border-radius: 20%;

    }

    .name{
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size:2.5em;
        color:#3d3e42;
        line-height:1.1em;
        text-transform:uppercase;
    }
    .occupation{
        font-family: '-apple-system';
        font-size:"1.5em";
        color:"#5d6163";
        line-height:"1em";
        text-transform:"uppercase";
    }.contact{
        text-align:"left";
        display:"flex";
        justify-content:"center";
        line-height:"1em";
    }
    a {
        text-decoration:"none";
        color: #fafeff;
    }
    .listItem.hover{
        
            color:"#5d6163";
    }
    .objective{
        width:"80%";
        margin:"auto";
        color:"#696564";
        text-align:"center";
    }
    .social{
        width:"80%";
        margin:"auto";
        line-height:"1em";
     }
    a{
        text-decoration:"none";
        color:"black";
    }
    .timelinePaper{
        padding: '6px 16px';
    }
    .timelinePaper.hover{
            box-shadow:"5px 5px #888888";
            background-color:"#fafeff";
    }
