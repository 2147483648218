
.btn {
  border-radius: 50px;
  padding: 14px 0;
  width: 160px;
  cursor: pointer;
  transition: 0.2s;
  font-family: "Poppins SemiBold";
}

.btn:active {
  transform: translateY(2px);
}

.primary-btn {
  color: var(--white);
  border: 2px solid linen;
  font-size: 12px;
  background-color: #1f2235;
}


.primary-btn:hover {
  color: aliceblue;
  border: 2px solid #ff5823;
  background-color: #1f2235;
}

.highlighted-btn {
  color: var(--white);
  font-size: 12px;
  background-color: #ff5823;
}

.red-border {
  border: 1px solid var(--error-message) !important;
}

.fade-in {
  opacity: 0;
  transform: translateY(80px);
  transition: all 0.8s ease;
}
