.footer-container{
    height: 17.8%;
    width: 100%;
    overflow: hidden;
    justify-content: center;
    display: flex;
}
.footer-parent{
    max-width: 1920px;
    height: 18vh;
    width: 100%;
}
.footer-parent img {
    max-width: 100%;
    height: 20vh;

}
@media only screen  and(max-width:1110px){
    .footer-parent{
        display: flex;
        align-items: flex-end;
    }
    
}
